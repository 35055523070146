import { CSVParseParam } from 'csvtojson/v2/Parameters';
import csvToJson from 'csvtojson';
import { Schema } from 'yup';
import { merge } from 'lodash';
import {
  InvalidFileFormatError,
  InvalidFileTypeError,
  InvalidFileContentError,
  TooManyRowsError
} from './errors';
export {
  CSVParserError,
  InvalidFileFormatError,
  InvalidFileTypeError,
  InvalidFileContentError,
  TooManyRowsError
} from './errors';

interface CSVParserOptions {
  parserOptions?: Partial<CSVParseParam>;
  maxRows?: number | null;
  schema?: Schema | null;
}

const DEFAULT_OPTIONS = {
  parserOptions: {
    delimiter: [',', ';', '|', '$']
  },
  maxRows: null,
  schema: null
};
export default class CSVParser<ExpectedCSVContent> {
  private readonly file: File;
  private readonly fileReader: FileReader;
  private readonly options: CSVParserOptions;
  private parsedData: ExpectedCSVContent[] | null = null;

  constructor(file: File, options: CSVParserOptions = DEFAULT_OPTIONS) {
    this.file = file;
    this.fileReader = new FileReader();
    this.options = merge(DEFAULT_OPTIONS, options);
  }

  private assertIsCsvFile() {
    if (this.file.type !== 'text/csv') {
      throw new InvalidFileTypeError();
    }
  }

  private assertMaxRows() {
    if (this.parsedData === null) {
      throw new Error(
        'Unexpected error: assertMaxRows has been called before file content is parsed'
      );
    }

    if (this.options.maxRows && this.parsedData.length > this.options.maxRows) {
      throw new TooManyRowsError({ maxEntries: this.parsedData.length });
    }
  }

  private assertSchema() {
    if (this.parsedData === null) {
      throw new Error(
        'Unexpected error: assertSchema has been called before file content is parsed'
      );
    }
    const schema = this.options.schema ?? null;
    if (schema === null) return;
    this.parsedData.every((entry, index) => {
      try {
        schema.validateSync(entry);
      } catch (err) {
        throw new InvalidFileContentError({
          detailedMessage: (err as Error).message,
          row: index + 1
        });
      }
    });
  }

  private async readFile() {
    const contentPromise = new Promise<string>((resolve, reject) => {
      this.fileReader.onload = ({ target }) => {
        if (!target?.result || typeof target?.result !== 'string')
          return reject(new InvalidFileFormatError());
        return resolve(target.result as string);
      };
    });
    this.fileReader.readAsText(this.file);
    return contentPromise;
  }

  public async parse() {
    this.assertIsCsvFile();

    const content = await this.readFile();
    this.parsedData = (await csvToJson(this.options.parserOptions).fromString(
      content
    )) as ExpectedCSVContent[];

    this.assertMaxRows();
    this.assertSchema();
    return this.parsedData;
  }
}

export function toCSV(columns: string[], data: {[key: string]: any}[]): string {
    const replacer = (key: any, value: string) => value === null ? '' : value
    let csv = data.map((row: {[key: string]: any}) => columns.map(column => JSON.stringify(row[column], replacer)).join(','))
    csv.unshift(columns.join(','))
    return csv.join('\r\n')
}

export function downloadCSV(filename: string, data: string) {
  var link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(data));
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
