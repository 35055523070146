import * as yup from 'yup';
import { Formik, Field, Form, FieldProps, FieldArray, FormikHelpers } from 'formik';
import { Campaign, CampaignDeliveryTypes } from '../../../../models/campaign';
import { Lead } from '../../../../models/lead';
import { AnySequenceInput, DeliveryTypeFormInput, Sequence, SequenceFormInput } from '../../../../models/sequence';
import ParametersFormInput from './ParametersFormInput';

export type FormValues = {[key: string]: any}

interface ParametersFormProps {
  campaign: Campaign;
  sequence: Sequence;
  onSubmit: (values: FormValues, triggerProcessing: boolean, formHelpers: FormikHelpers<FormValues>) => void | Promise<void>;
}

export default function ParametersForm({ campaign, sequence, onSubmit }: ParametersFormProps) {
  const inputs = sequence.form.inputs;

  // this proxy is a bullshit that add a `triggerProcessing` mode to form submission
  let triggerProcessing = false;
  const onSubmitProxy = (
    parameters: FormValues,
    formHelpers: FormikHelpers<FormValues>
  ) => {
    onSubmit(parameters, triggerProcessing, formHelpers)
  };

  // initial values
  const initialValuesSequence = inputs.reduce((acc, input) => ({
    ...acc,
    [input.slug]: input.defaultValue
  }), {}) as FormValues;
  const initialValuesAnySequence = AnySequenceInput.reduce((acc, input) => ({
    ...acc,
    [input.slug]: input.defaultValue
  }), {}) as FormValues;
  const initialValuesDeliveryType = {[DeliveryTypeFormInput.slug]: campaign.delivery_type ?? CampaignDeliveryTypes[0]};
  const initialValues = Object.assign({}, initialValuesSequence, initialValuesAnySequence, initialValuesDeliveryType, campaign.sequence)

  // validation
  const validationSchemaSequence = inputs.reduce((acc, input) => ({
    ...acc,
    [input.slug]: input.validator
  }), {}) as FormValues;
  const validationSchemaAnySequence = AnySequenceInput.reduce((acc, input) => ({
    ...acc,
    [input.slug]: input.validator
  }), {}) as FormValues;
  const validationSchemaDeliveryType = {[DeliveryTypeFormInput.slug]: DeliveryTypeFormInput.validator};
  const validationSchema = yup.object(Object.assign({}, validationSchemaSequence, validationSchemaAnySequence, validationSchemaDeliveryType))

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitProxy}>
      {({ isValid, isSubmitting, status, values }) => (
        <Form>
          {status?.formError && (
            <p className="text-center text-sm text-red-600">{status?.formError}</p>
          )}
          <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-8 border-b border-gray-900/10 pb-12 sm:grid-cols-6">
            <div className="col-span-full">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Your company context</h2>

              <p className="text-sm leading-6 text-gray-600">
                This information is related to the campaign editor.
              </p>
            </div>

            {inputs.map((input) => 
              <ParametersFormInput values={values} input={input} />
            )}
          </div>

          <div className="mx-auto grid grid-cols-1 gap-x-6 gap-y-8 py-12 sm:grid-cols-6">
            <div className="col-span-full ">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Campaign context</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This information is realted to the campaign you are currently creating.
              </p>
            </div>

            <ParametersFormInput values={values} input={DeliveryTypeFormInput} />

            {AnySequenceInput.map((input) => 
              <ParametersFormInput values={values} input={input} />
            )}
          </div>

          <div className="flex justify-end">
            <button
              onClick={() => (triggerProcessing = false)}
              type="submit"
              className="inline-flex items-center gap-x-1 rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Save
            </button>
            <button
              onClick={() => (triggerProcessing = true)}
              type="submit"
              className="inline-flex ml-4 items-center gap-x-1 rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Save and generate {campaign.lead_count ?? 0} {`sequence${campaign.lead_count ?? 0 > 1 ? 's' : ''}`}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
