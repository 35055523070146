import * as yup from 'yup';
import {  Field, FieldProps, FieldArray,  } from 'formik';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ChangeEvent } from 'react';
import { SequenceFormInput } from '../../../../models/sequence';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function getPlaceholder(index :number, placeholder: string|string[]|null) {
  if (!placeholder) {
    return "";
  } else if (Array.isArray(placeholder)) {
    return placeholder[index % placeholder.length];
  }
  return placeholder;
}

interface ParametersFormProps {
  input: SequenceFormInput;
  values :any;
}

export default function ParametersFormInput({ input, values }: ParametersFormProps) {
  const withMaxWordsLimit = (handler: React.ChangeEventHandler<HTMLInputElement>, maxWords = 15) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Only call handler if maxWords is not reached
    if (value.split(' ').length <= maxWords) {
      handler(event);
    }
  };

  switch (input.type){
    case "input":
      return (
        <Field name={input.slug}>
          {({ field, meta }: FieldProps) => (
            <div className="sm:col-span-full">
              <label
                htmlFor={input.slug}
                className="block text-sm font-medium leading-6 text-gray-900">
                {input.name}
              </label>
              {input.description &&
                <p className="text-xs text-gray-400" id={input.slug + "-description"}>
                  {input.description}
                </p>
              }
              <div className="mt-2">
                <input
                  id={input.slug}
                  type="text"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  placeholder={getPlaceholder(0, input.placeholder)}
                  required={input.required}
                  aria-invalid={meta.touched && !!meta.error}
                  className={classNames(
                    meta.touched && meta.error
                      ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                      : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                    'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                  )}
                />
              </div>
              {meta.touched && !!meta.error && (
                <p className="mt-2 text-sm text-red-600" id={input.slug + "-error"}>
                  {meta.error}
                </p>
              )}
            </div>
          )}
        </Field>
      )

    case "textarea":
      return (
        <Field name={input.slug}>
          {({ field, meta }: FieldProps) => (
            <div className="sm:col-span-full">
              <label
                htmlFor={input.slug}
                className="block text-sm font-medium leading-6 text-gray-900">
                {input.name}
              </label>
              {input.description &&
                <p className="text-xs text-gray-400" id={input.slug + "-description"}>
                  {input.description}
                </p>
              }
              <div className="mt-2">
                <textarea                 
                  id={input.slug}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  placeholder={getPlaceholder(0, input.placeholder)}
                  required={input.required}
                  aria-invalid={meta.touched && !!meta.error}
                  rows={5}
                  className={classNames(
                    meta.touched && meta.error
                      ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                      : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                    'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                  )}>
                </textarea>
              </div>
              {meta.touched && !!meta.error && (
                <p className="mt-2 text-sm text-red-600" id={input.slug + "-error"}>
                  {meta.error}
                </p>
              )}
            </div>
          )}
        </Field>
      )

    case "multi_input":
      return (
        <div className="col-span-full">
          <label
            htmlFor={`${input.slug}[${values[input.slug].length - 1}]`}
            className="block text-sm font-medium leading-6 text-gray-900">
            {input.name}
          </label>
          <p className="text-xs text-gray-400" id="email-description">
            {input.description}
          </p>
          <FieldArray
            name={input.slug}
            render={(arrayHelpers: any) => (
              <div className="mt-2 space-y-2">
                {values[input.slug].map((value: any, index: number) => {
                  const isLastElement = index === values[input.slug].length - 1;
                  return (
                    <div key={index}>
                      <Field name={`${input.slug}.${index}`}>
                        {({ field, meta }: FieldProps) => (
                          <>
                            <div className="flex items-center space-x-2 sm:space-x-4">
                              <input
                                id={`${input.slug}[${index}]`}
                                type="text"
                                name={field.name}
                                value={field.value}
                                onChange={withMaxWordsLimit(field.onChange, 15)}
                                onBlur={field.onBlur}
                                placeholder={getPlaceholder(index, input.placeholder)}
                                required={input.required}
                                aria-invalid={meta.touched && !!meta.error}
                                className={classNames(
                                  meta.touched && meta.error
                                    ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                                    : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                                  'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                                )}
                              />
                              {values[input.slug].length > 1 && index !== 0 && (
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                  className="rounded-full bg-yellow-500 p-1 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                              )}
                            </div>
                            {meta.touched && !!meta.error && (
                              <p className="mt-2 text-sm text-red-600" id="email-error">
                                {meta.error}
                              </p>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  );
                })}
                <button
                  type="button"
                  onClick={() => arrayHelpers.insert(values[input.slug].length, '')}
                  className="inline-flex items-center gap-x-1 rounded-md border-[1px] border-yellow-500 px-2.5 py-1.5 text-xs font-medium text-white text-yellow-500 shadow-sm hover:bg-yellow-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
                  <PlusIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                  {input.add_button_label}
                </button>
              </div>
            )}
          />
        </div>
      )

      case 'select':
        return <Field name={input.slug}>
          {({ field, meta }: FieldProps) => (
            <div className="sm:col-span-full">
              <label
                htmlFor={input.slug}
                className="block text-sm font-medium leading-6 text-gray-900">
                {input.name}
              </label>
              <p className="text-xs text-gray-400" id="email-description">
                {input.description}
              </p>
              <div className="mt-2">
                <select
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  defaultValue={input.defaultValue}
                  className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6">
                  {input.options.map(({key, label}) => (
                    <option key={key} value={key}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              {meta.touched && !!meta.error && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {meta.error}
                </p>
              )}
            </div>
          )}
        </Field>
      default:
        return <></>
  }
}
