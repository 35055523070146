
export const CampaignSequenceTypes = ['email', 'linkedin'] as const;
export const CampaignDeliveryTypes = ['lemlist' , 'outreach','salesloft','instantly','smartlead','la-growth-machine','emelia'] as const;

export type CampaignStatus = 'creating'|'processing'|'done';
export type CampaignSequenceType = typeof CampaignSequenceTypes[number];
export type CampaignDeliveryType = typeof CampaignDeliveryTypes[number];

export type Campaign = {
  id: string;
  user_id: string;
  name: string;
  status: CampaignStatus;
  sequence_type: CampaignSequenceType;
  sequence: object;
  delivery_type: CampaignDeliveryType;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  // not a column: computed from a JOIN
  // optional
  lead_count?: number;
};

export function adaptObjectToCampaign(data: any): Campaign{
  return {
    ...data,
    status: data['status'] as CampaignStatus,
    sequence_type: data['sequence_type'] as CampaignSequenceType,
    sequence: data['sequence'] as object,
    delivery_type: data['delivery_type'] as CampaignDeliveryType,
    created_at: new Date(data['created_at']),
    updated_at: new Date(data['updated_at']),
    deleted_at: data['deleted_at'] ? new Date(data['deleted_at']) : null,
  }
}
