import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ParametersForm, { FormValues } from './ParametersForm';
import { FormikHelpers } from 'formik';
import Loader from '../../../../designSystem/Loader';
import { getCampaignById, updateCampaignSettings, updateCampaignStatus } from '../../../../repositories/campaign';
import { useMutation, useQuery } from 'react-query';
import { SequenceTemplates } from '../../../../models/sequence';
import { CampaignDeliveryType } from '../../../../models/campaign';
import { NotificationType, useNotificationCenter } from '../../../../lib/notifications/NotificationProvider';

export default function ParametersView() {
  const notificationCenter = useNotificationCenter();
  const navigate = useNavigate();
  const params = useParams();
  const [queryParameters] = useSearchParams()
  const campaignId = params.campaignId as string;
  const sequenceType = queryParameters.get('sequence_type');

  const sequence = SequenceTemplates.find((s) => s.type === sequenceType?.trim());
  if (!sequence) {
    navigate(`/campaign/${campaignId}/sequences`)
    return <></>
  }

  const { isLoading: isLoadingCampaign, data: campaign,  } = useQuery(
    [campaignId, "get-campaign-by-id"],
    async () => {
      const campaign = await getCampaignById(campaignId);
      if (campaign.status !== 'creating')
        navigate(`/campaign/${campaignId}/copywriting`)
      if (campaign.sequence === null || campaign.sequence_type === null)
        navigate(`/campaign/${campaignId}/sequences`)
      return campaign;
    }
  );

  const { mutate: saveParameters } = useMutation(async (variables: {parameters: object, deliveryType: CampaignDeliveryType, triggerProcessing:boolean}) => {
    await updateCampaignSettings(campaign?.id!, campaign?.name!, sequence.type, variables.parameters, variables.deliveryType);
    if (!variables.triggerProcessing) {
      notificationCenter.showNotification({
        title: "Sequence saved",
        content: '',
        type: NotificationType.SUCCESS,
        autoDismissAfter: 10
      });
      return
    }

    await updateCampaignStatus(campaign?.id!)
    navigate(`/campaign/${campaignId}/copywriting`)
  });

  const onSubmit = (
    parameters: FormValues,
    triggerProcessing: boolean,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    const deliveryType = parameters["delivery_type"];
    delete parameters["delivery_type"];
    saveParameters({parameters, deliveryType, triggerProcessing})
  };

  return (
    <div className="mx-auto max-w-2xl px-4 py-10 sm:px-6">
      {isLoadingCampaign || !campaign ? (
        <Loader />
      ) : (
        <ParametersForm campaign={campaign} sequence={sequence} onSubmit={onSubmit} />
      )}
    </div>
  );
}
