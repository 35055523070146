import {  NavLink, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../designSystem/Loader';
import { getCampaignById } from '../../../../repositories/campaign';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { getLeadsByCampaignId } from '../../../../repositories/lead';
import CopyrightingTable, { } from './CopyrightingTable';
import { Lead } from '../../../../models/lead';
import { downloadCSV, toCSV } from '../../../../lib/csvParser/csvParser';

export default function CopyrightingView() {
  const params = useParams();
  const navigate = useNavigate();
  const campaignId = params.campaignId as string;
  const [columns, setColumns] = useState<string[]>([]);

  const { isLoading: isLoadingCampaign, data: campaign, refetch: refetchCampaign } = useQuery(
    [campaignId, "get-campaign-by-id"],
    async () => {
      const campaign = await getCampaignById(campaignId);
      if (campaign.status === 'creating')
        navigate(`/campaign/${campaignId}/leads`)
      if (campaign.status === 'done')
        refetchLeads()
      return campaign;
    }
  );

  const { isLoading: isLoadingLead, data: leads, refetch: refetchLeads } = useQuery(
    [campaignId, campaign, "get-leads-by-campaign-id"],
    async () => {
      if (campaign?.status !== 'done')
        return [];

      const leads:Lead[] = (await getLeadsByCampaignId(campaignId))
        .filter((lead: Lead) => lead.status === 'done');  // exclude errors
      const columnSlugs:string[] = [] ;
      const columnsAdded: {[key: string]:boolean} = {};
      
      const kvLeads: {[key: string]: any}[] = leads.map((l): {[key: string]: any} => {
        const columnSlugsForCurrentLead = Object.keys(l.properties)
        columnSlugsForCurrentLead.push(...l.sequence?.reduce((acc: string[], item) => {
          if (item.subject) {
            acc.push(`${item.slug}_subject`)
          }
          if (item.icebreaker) {
            acc.push(`${item.slug}_icebreaker`)
          }
          if (item.content) {
            acc.push(`${item.slug}_content`)
          }
          return acc;
        }, []) ?? []);

        for (let slug of columnSlugsForCurrentLead) {
          if (!columnsAdded[slug]) {
            columnsAdded[slug] = true;
            columnSlugs.push(slug)
          }
        }

        return l.sequence?.reduce((acc: {[key: string]: any}, curr) => {
          return {
            ...acc,
            [`${curr.slug}_subject`]: curr.subject,
            [`${curr.slug}_icebreaker`]: curr.icebreaker,
            [`${curr.slug}_content`]: curr.content,
          }
        }, {}) ?? [];
      });

      setColumns(columnSlugs);
      return kvLeads;
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (campaign?.status !== 'done')
        refetchCampaign()
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  function download() {
    const str = toCSV(columns, leads ?? [])
    downloadCSV(`campaign export - ${campaign?.name}.csv`, str)
  }

  return (
    <div className="mx-auto max-w-4xl px-4 py-10 sm:px-6">
      {(isLoadingCampaign || isLoadingLead) &&
        <Loader />}

      {!isLoadingCampaign && !isLoadingLead && campaign?.status === 'processing' &&
        <div>
          <Loader message="Campaign is processing... It can take a few minutes." />
        </div>
      }

      {!isLoadingCampaign && !isLoadingLead && campaign?.status === 'done' &&
        <>
          <p className="mr-2 flex items-center justify-center">
              Download&nbsp;
              <button className="text-yellow-500" onClick={download}>
                CSV!
              </button>
          </p>
          <CopyrightingTable leads={leads ?? []} columns={columns} />
        </>
      }
    </div>
  );
}
