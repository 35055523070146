

export type User = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  created_at: Date;
  updated_at: Date;
}

export function adaptObjectToUser(data: any): User {
  return {
   ...data,
    created_at: new Date(data['created_at']),
    updated_at: new Date(data['updated_at']),
  }
}
