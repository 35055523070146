import {  useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../designSystem/Loader';
import { getCampaignById } from '../../../../repositories/campaign';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { LeadImportCurrentStep } from './components/LeadImport';

export default function CampaignLeadsView() {
  const params = useParams();
  const navigate = useNavigate();
  const campaignId = params.campaignId as string;
  const [currentStep, setCurrentStep] = useState(0);

  const { isLoading : isCampaignLoading, data: campaign, refetch } = useQuery(
    [campaignId, "get-campaign-by-id"],
    async () => {
      const campaign = await getCampaignById(campaignId);
      if (campaign.status != 'creating')
        navigate(`/campaign/${campaignId}/copywriting`)
      return campaign;
    }
  );

  const onContinue = () => {
    navigate(`/campaign/${campaignId}/sequences`);
  };

  return (
    <div className="mx-auto max-w-4xl px-4 py-10 sm:px-6">
      {isCampaignLoading &&
        <Loader />}

      {!isCampaignLoading && campaign && currentStep === 0 &&
        <LeadImportCurrentStep campaign={campaign} onContinue={onContinue} onUpdate={refetch} />}
    </div>
  );
}
