import { CampaignDeliveryTypes, CampaignSequenceType } from "./campaign";
import testSequence from '../assets/test-sequence.png'
import * as yup from 'yup';

type ResourceType = 'product_demo'|'article'|'document'|'computer_graphics'|'video'|'custom_video'|'case_study'|'guide_white_paper';

const Resources: {key: ResourceType, label: string}[] = [
    {key:  'product_demo', label:'Product demo'},
    {key:  'article', label: 'Article'},
    {key:  'document', label:'Document'},
    {key:  'computer_graphics', label:'Computer graphics'},
    {key:  'video', label:'Video'},
    {key:  'custom_video', label:'Custom video'},
    {key:  'case_study', label:'Case study'},
    {key:  'guide_white_paper', label:'Guide / White paper'},
];

type LanguageTone = 
  | 'formal'
  | 'informal'
  | 'enthousiastic'
  | 'humoristic'
  | 'persuasive'

const LanguageTones: {key: LanguageTone, label: string}[] = [
{key:  "formal", label:'Formel'},
{key:  "informal", label:'Informel'},
{key:  "enthousiastic", label:'Enthousiaste'},
{key:  "humoristic", label:'Humoristique'},
{key:  "persuasive", label:'Persuasif'}
];

type Language = 
  | "fr_vous"
  | "fr_tu"
  | "en"

const Languages: {key: Language, label: string}[] = [
{key:   "fr_vous", label:'French (vous)'},
{key:   "fr_tu", label:'French (tu)'},
{key:   "en", label:'English'}
];

type IntentData = 
  | "none"
  | "recruitment"
  | "event"
  | "techno"

const IntentDatas: {key: IntentData, label: string}[] = [
{key:   "none", label:'Pas d\'intent data'},
{key:   "recruitment", label:'Recrutement en cours'},
{key:   "event", label:'Événement auquel les prospects ont participé'},
{key:   "techno", label:'Utilisation d\'une technologie particulière'}
];

export type SequenceFormInput = {
    slug: string;
    name: string;
    description: string|null;
    defaultValue: any;
    validator: yup.AnyObject,
    required: boolean;
} & (
    {
        type: 'input';
        placeholder: string|null;
    }
    |
    {
        type: 'textarea';
        placeholder: string|null;
    }
    |
    {
        type: 'multi_input';
        placeholder: string[]|null;
        add_button_label: string;
    }
    |
    {
        type: 'select';
        options: {key: any, label: string}[];
    }
);
    
export type Sequence = {
  type: CampaignSequenceType;
  name: string;
  pictureUrl: string;
  description: string;
  form: {
    inputs: SequenceFormInput[]
  }
};

export const SequenceTemplates: Sequence[] = [
  {
    type: "email",
    name: "Sequence Conversationnelle",
    pictureUrl:testSequence,
    description: "Séquence de 4 emails avec une approche conversationnelle sur les 2 premiers mails. Au 3ème mail, on pitch la solution et on propose une vidéo personnalisée pour en savoir plus.",
    form: {
        inputs: [
            {
                type: "input",
                slug: "company_name",
                name: "Company name",
                description: "Le nom de l'entreprise qui expédie cette séquence",
                placeholder: "Snipr",
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
            {
                type: "input",
                slug: "cible",
                name: "Cible",
                description: "Le type de personae et l'ICP visé. 1 seul type de personae et d'ICP donne de meilleurs résultats.",
                placeholder: "ex: Head of Sales dans des startups B2B françaises",
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
            {
                type: "textarea",
                slug: "usp",
                name: "USP",
                description: "Votre Unique Selling Proposition : Chez [Entreprise], nous aidons les [Personae] à obtenir [Bénéfices] grâce à notre [Secret Sauce] en moins de [Durée].",
                placeholder: "ex: Chez Snipr, nous aidons les SDR à doubler leur taux de réponse en ultra-personnalisant automatiquement leurs campagnes de cold emails en moins de 15min.",
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
            {
                type: "input",
                slug: "references",
                name: "Références",
                description: "Vos références client",
                placeholder: "Qonto, Doctolib, Blablacar",
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
            // {
            //     type: "multi_input",
            //     slug: "problems",
            //     name: "Problems",
            //     description: "Add problems you want to discuss in your message(s) (15 words max)",
            //     placeholder: [
            //         'ex: My email response rate is low',
            //         "ex: I take a full afternoon for writing my campaigns' emails"
            //     ],
            //     defaultValue: [''],
            //     required: true,
            //     validator: yup
            //         .array()
            //         .of(yup.string().required('Please enter a problem'))
            //         .min(1, 'Please provide at least 1 problem your company is solving'),
            //     add_button_label: "Add problem"
            // },
            // {
            //     type: "multi_input",
            //     slug: "stakes",
            //     name: "Stakes",
            //     description: "Add your prospects' stakes you want to discuss in your message(s) (15 words max)",
            //     placeholder: [
            //         'ex: Increase their number of qualified meetings',
            //         'ex: Get their variable compensation at the end of month'
            //     ],
            //     defaultValue: [''],
            //     required: true,
            //     validator: yup
            //         .array()
            //         .of(yup.string().required('Please enter a stake'))
            //         .min(1, 'Please provide at least 1 stake of the client you target'),
            //     add_button_label: "Add stake"
            // },
            // {
            //     type: "multi_input",
            //     slug: "resources",
            //     name: "Resources",
            //     description: "Add resource(s) you want to share in your message(s)",
            //     placeholder: [
            //         'ex: https://www.youtube.com/watch?v=<...>'
            //     ],
            //     defaultValue: [''],
            //     required: true,
            //     validator: yup
            //         .array()
            //         .of(yup.string().required('Please enter a stake'))
            //         .min(1, 'Please provide at least 1 stake of the client you target'),
            //     add_button_label: "Add resource"
            // },
            // {
            //     type: "multi_input",
            //     slug: "value_propositions",
            //     name: "Value propositions",
            //     description: "Add your product's value proposition(s) you want to discuss in your message(s) (15 words max)",
            //     placeholder: [
            //         'Double the reponse rate',
            //         'Divide by 10 time to write cold emails'
            //     ],
            //     defaultValue: [''],
            //     required: true,
            //     validator:  yup
            //         .array()
            //         .of(yup.string().required('Please enter a value proposition'))
            //         .min(1, 'Please provide at list 1 benefit of your solution'),
            //     add_button_label: "Add value proposition"
            // },
            // {
            //     type: "multi_input",
            //     slug: "references",
            //     name: "References",
            //     description: "Add your clients references you want to mention in your message(s) (15 words max)",
            //     placeholder: ['Qonto', 'Doctolib'],
            //     defaultValue: [''],
            //     required: true,
            //     validator: yup.array().of(
            //         yup.object({
            //             url: yup
            //                 .string()
            //                 .required('Please this is required'),
            //             type: yup.string().oneOf(Resources.map(({key}) => key))
            //         })
            //     ),
            //     add_button_label: "Add reference"
            // },
        ]
    }
  },
  {
    type: "email",
    name: "Sequence Classique",
    pictureUrl: testSequence,
    description: "Séquence de 4 emails avec une approche classique, excepté un CTA vidéo au premier mail.",
    form: {
        inputs: [
            {
                type: "input",
                slug: "company_name",
                name: "Company name",
                description: "Le nom de l'entreprise qui expédie cette séquence",
                placeholder: null,
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
            {
                type: "input",
                slug: "cible",
                name: "Cible",
                description: "Le type de personae et l'ICP visé. 1 seul type de personae et d'ICP donne de meilleurs résultats.",
                placeholder: "ex: Head of Sales dans des startups B2B françaises",
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
            {
                type: "input",
                slug: "usp",
                name: "USP",
                description: "Votre Unique Selling Proposition : Chez [Entreprise], nous aidons les [Personae] à obtenir [Bénéfices] grâce à notre [Secret Sauce] en moins de [Durée].",
                placeholder: "ex: Chez Snipr, nous aidons les SDR à doubler leur taux de réponse en ultra-personnalisant automatiquement leurs campagnes de cold emails en moins de 15min.",
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
            {
                type: "input",
                slug: "references",
                name: "Références",
                description: "Vos références client",
                placeholder: "Qonto, Doctolib, Blablacar",
                defaultValue: "",
                required: true,
                validator: yup.string().required(),
            },
        ]
    }
  }
]

// the following input fields are used in every sequences
export const AnySequenceInput: SequenceFormInput[] = [
    {
        type: "select",
        slug: "language_tone",
        name: "Ton",
        description: "Selectionne le ton que tu veux utiliser pour la séquence",
        defaultValue: 'formal',
        required: true,
        validator: yup.string().oneOf(LanguageTones.map(({key}) => key)).required('Sélection requise'),
        options: LanguageTones,
    },
    {
        type: "select",
        slug: "language",
        name: "Langue",
        description: "Selectionne la langue dans laquelle tu souhaites générer la séquence",
        defaultValue: 'fr_vous',
        required: true,
        validator: yup.string().oneOf(Languages.map(({key}) => key)).required('Sélection requise'),
        options: Languages,
    },
    {
        type: "select",
        slug: "intent_data",
        name: "Intent Data",
        description: "Comment avez-vous constitué votre base de prospect",
        defaultValue: 'Pas d\'intent data',
        required: true,
        validator: yup.string().oneOf(IntentDatas.map(({key}) => key)).required('Sélection requise'),
        options: IntentDatas,
    },
];

export const DeliveryTypeFormInput: SequenceFormInput = {
  type: "select",
  slug: "delivery_type",
  name: "Outil d'envoi",
  description: "Quel outil d'envoi utilisez-vous ?",
  defaultValue: null,
  required: true,
  validator: yup.string().oneOf(CampaignDeliveryTypes).required('Please provide the delivery channel'),
  options: CampaignDeliveryTypes.map((t) => ({key: t, label: t.charAt(0).toUpperCase() + t.slice(1)}))
};
