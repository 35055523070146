import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import NewCampaignForm, { CampaignFormProps } from './NewCampaignForm';

interface CampaignEmptyStateProps {
  onCreateCampaign: (name:string|null) =>void;
  isSubmitting: boolean
}

export default function CampaignEmptyState({ onCreateCampaign, isSubmitting }: CampaignEmptyStateProps) {
  return (
    <div className="max-h-lg mx-auto mt-28 max-w-lg">
      <div>
        <div className="text-center">
          <EnvelopeIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">
            Create a campaign
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Well, it looks quite empty here ! Launch your first campaign 👇
          </p>
        </div>

        {/* <div className="text-center mt-10">
          <button
            onClick={onCreateCampaign}
            className="flex-shrink-0 rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
            Create a campaign
          </button>
        </div> */}
        <NewCampaignForm onCreateCampaign={onCreateCampaign} isSubmitting={isSubmitting}/>
      </div>
    </div>
  );
}
