import { CampaignSequenceType } from '../../../../models/campaign';
import { Sequence } from '../../../../models/sequence';


interface SequenceListProps {
  sequences: Sequence[];
  onSequenceSelection: (type: CampaignSequenceType) => void;
}

export default function SequenceList({ sequences, onSequenceSelection }: SequenceListProps) {
  return (
    <ul
      role="list"
      className="flex flex flex-wrap justify-center gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {sequences.map((sequence) => (
        <li
          key={sequence.type}
          className="col-span-1 flex max-w-[230px] flex-col rounded-lg bg-white text-center shadow">
          <div className="aspect-h-7 aspect-w-10 group block w-full overflow-hidden rounded-t-lg bg-gray-100 focus-within:ring-2 focus-within:ring-yellow-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <img src={sequence.pictureUrl} alt="" className="pointer-events-none object-cover" />
          </div>
          <div className="flex flex-1 flex-col p-2 md:p-4">
            <p className="pointer-events-none mb-2 block truncate text-sm font-medium text-gray-900">
              {sequence.name}
            </p>
            <p className="pointer-events-none mb-2 line-clamp-3 block text-sm text-gray-500">
              {sequence.description}
            </p>

            <button
              type="button"
              onClick={() => onSequenceSelection(sequence.type)}
              className="mt-auto rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Select
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
  return <></>
}
