import { useNavigate, useParams } from 'react-router-dom';
import SequenceList, {  } from './SequenceList';
import Loader from '../../../../designSystem/Loader';
import { CampaignSequenceType } from '../../../../models/campaign';
import { getCampaignById, updateCampaignSettings } from '../../../../repositories/campaign';
import { useQuery } from 'react-query';
import { SequenceTemplates } from '../../../../models/sequence';

export default function SequencesView() {
  const navigate = useNavigate();
  const params = useParams();
  const campaignId = params.campaignId as string;

  const { isLoading, data: campaign, refetch } = useQuery(
    [campaignId, "get-campaign-by-id"],
    async () => {
      const campaign = await getCampaignById(campaignId);
      if (campaign.status != 'creating')
        navigate(`/campaign/${campaignId}/copywriting`)
      return campaign;
    }
  );

  const onSequenceSelection = async (type: CampaignSequenceType) => {
    try {
      if (!campaign){
        throw Error('missing campaign')
      }

      await updateCampaignSettings(campaign.id, campaign.name, type, {}, null);
      navigate(`/campaign/${campaignId}/parameters?sequence_type=${type}`);
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <div className="mx-auto max-w-5xl px-4 py-10 sm:px-6">
      {isLoading && <Loader />}
      {!isLoading && campaign && <SequenceList sequences={SequenceTemplates} onSequenceSelection={onSequenceSelection} />}
    </div>
  );
}
