import { Lead } from '../../../../../models/lead';
import * as yup from 'yup';

interface LeadsTableProps {
  leads: Lead[];
  columns: Column[];
}

export interface Column {
  name: string;
  slug:string;
  validator:yup.AnyObject;
}

export default function LeadsTable({ leads, columns }: LeadsTableProps) {
  return (
    <div className="flow-root">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.name}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {leads.map((lead) => (
                  <tr key={lead.id}>
                    {columns.map((column) => (
                      <td
                        key={column.name}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                        {column.slug ? lead.properties?.[column.slug] : null}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
