
export const LeadStatuses = ['added','processing','done', 'error'] as const;

export type LeadStatus = typeof LeadStatuses[number];

export type Lead = {
  id: string;
  campaign_id: string;
  properties: {[key: string]: string};
  status: LeadStatus;
  sequence?: LeadSequenceItem[];
  created_at: Date;
  updated_at: Date;
  processed_at: Date|null;
};

export function adaptObjectToLead(data: any): Lead{
  return {
    ...data,
    status: data['status'] as LeadStatus,
    sequence: data['sequence']?.map(adaptObjectToLeadSequenceItem),
    created_at: new Date(data['created_at']),
    updated_at: new Date(data['updated_at']),
  }
}

export const LeadSequenceItemTypes = ['email'] as const;

export type LeadSequenceItemType = typeof LeadSequenceItemTypes[number];

export type LeadSequenceItem = {
  nth: number,
  type: LeadSequenceItemType, // type of message
  slug: string,               // column name in csv
  subject?: string;
  icebreaker?: string;
  content?: string;
}

export function adaptObjectToLeadSequenceItem(data: any): LeadSequenceItem{
  return {
    ...data,
    type: data['type'] as LeadSequenceItemType,
  }
}
