import axios from "axios";
import { CampaignDeliveryType, CampaignSequenceType, adaptObjectToCampaign } from "../models/campaign";

export function getCampaigns() {
    return axios.get('/campaign', {    })
        .then((response)=> response?.data?.map(adaptObjectToCampaign))
}

export function getCampaignById(id: string) {
    return axios.get(`/campaign/${id}`, {    })
        .then((response)=> adaptObjectToCampaign(response?.data));
}

export function deleteCampaign(id: string) {
    return axios.delete(`/campaign/${id}`, {    });
}

export function createCampaign(name: string) {
    return axios
        .post(`/campaign`, {  
            name,
        })
        .then((r) => adaptObjectToCampaign(r.data));
}

export function updateCampaignSettings(id: string, name: string, sequenceType: CampaignSequenceType, sequence: object, deliveryType:CampaignDeliveryType|null) {
    return axios
        .put(`/campaign/${id}`, {  
            name,
            sequence_type: sequenceType,
            sequence,
            delivery_type: deliveryType,
        })
        .then((r) => adaptObjectToCampaign(r.data));

}

export function updateCampaignStatus(id: string) {
    return axios
        .put(`/campaign/${id}/status`, {      })
        .then((r) => adaptObjectToCampaign(r.data));
}
