import CampaignsTable from './components/CampaignsTable';
import {  useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {  createCampaign, deleteCampaign, getCampaigns } from '../../../repositories/campaign';
import { useMutation, useQuery } from 'react-query'
import CampaignEmptyState from './components/CampaignEmptyState';
import CampaignCreationModal from './components/CampaignCreationModal';

export default function CampaignsListView() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const { isLoading : loading, refetch, data: campaigns } = useQuery(
    ["get-all-campaigns"],
    async () => {
      return getCampaigns()
    },
    {
      onError: (err:Error) => {
        console.error(err);
      },
    }
  );

  const { isLoading : deleting, mutate: onDeleteCampaign } = useMutation(async (id:string) => {
    try {
      await deleteCampaign(id);
      return refetch();
    } catch (err) {
      console.error(err);
    }
  });

  const  { isLoading: isCreatingCampaign, mutate: onCreateCampaign } = useMutation(async ({name}:{name: string|null}) => {
    try {
      const campaign=  await createCampaign(name?.trim() ?? 'New campaign');
      navigate(`/campaign/${campaign.id}/leads`);
    } catch (err) {
      console.error(err);
    }
  })

  const onModalToggle = (open: boolean) => {
    setIsModalOpen(open);
  };

  return (
    <div className="overflow-auto px-4 py-6 sm:px-6 sm:py-10 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Campaigns</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {!loading && campaigns && campaigns.length !== 0 && (
            <button
              type="button"
              onClick={() => onModalToggle(true)}
              className="block rounded-md bg-yellow-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Add campaign
            </button>
          )}
        </div>
      </div>

      {!loading && !campaigns && (
        <p>Error: could not fetch campaigns</p>
      )}

      {!loading && campaigns&& campaigns.length === 0 && (
        <CampaignEmptyState  
          isSubmitting={isCreatingCampaign}
          onCreateCampaign={(campaignName) =>
            onCreateCampaign({name:campaignName})
          }
        />
      )}

      {!loading && campaigns && campaigns?.length !== 0 && (
        <CampaignsTable
          campaigns={campaigns}
          loading={loading}
          onDeleteCampaign={(campaignId) => onDeleteCampaign(campaignId)}
        />
      )}

      <CampaignCreationModal
        open={isModalOpen}
        isSubmitting={isCreatingCampaign}
        onClose={() => onModalToggle(false)}
        onCreateCampaign={(campaignName:string) =>
          onCreateCampaign({name:campaignName})
        }
      />
    </div>
  );
}
