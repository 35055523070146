import * as yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikConfig } from 'formik';
import { NavLink } from 'react-router-dom';

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const loginSchema = yup.object({
  email: yup.string().email('Please fill a valid email').required('Please enter your email'),
  password: yup.string().required('Please enter your password')
});

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface LoginFormProps {
  onSubmit: FormikConfig<FormValues>['onSubmit'];
}

export default function LoginForm(props: LoginFormProps) {
  return (
    <Formik<FormValues>
      initialValues={{ email: '', password: '', rememberMe: true }}
      validationSchema={loginSchema}
      onSubmit={props.onSubmit}>
      {({ isValid, isSubmitting, status }) => (
        <Form className="space-y-6">
          {status?.formError && (
            <p className="text-center text-sm text-red-600">{status?.formError}</p>
          )}
          <Field name="email">
            {({ field, meta }: FieldProps) => (
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="email"
                    autoComplete="email"
                    required
                    aria-invalid={meta.touched && !!meta.error}
                    className={classNames(
                      meta.touched && meta.error
                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                      'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                    )}
                  />
                </div>
                {meta.touched && !!meta.error && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {meta.error}
                  </p>
                )}
              </div>
            )}
          </Field>

          <Field name="password">
            {({ field, meta }: FieldProps) => (
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    type="password"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    autoComplete="current-password"
                    required
                    aria-invalid={meta.touched && !!meta.error}
                    className={classNames(
                      meta.touched && meta.error
                        ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                        : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                      'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                    )}
                  />
                </div>
                {meta.touched && !!meta.error && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {meta.error}
                  </p>
                )}
              </div>
            )}
          </Field>

          <div className="flex items-center justify-between">
            {/* <div className="flex items-center">
              <Field name="rememberMe">
                {({ field }: FieldProps) => (
                  <>
                    <input
                      id="rememberMe"
                      name={field.name}
                      onChange={field.onChange}
                      checked={field.value}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                    />
                    <label
                      htmlFor="rememberMe"
                      className="ml-3 block text-sm leading-6 text-gray-900">
                      Remember me
                    </label>
                  </>
                )}
              </Field>
            </div> */}

            {/* <div className="text-sm leading-6">
              <NavLink
                to="/reset-password/request"
                className="font-semibold text-yellow-500 hover:text-yellow-400">
                Forgot password?
              </NavLink>
            </div> */}
          </div>

          <div>
            <button
              type="submit"
              disabled={!isValid || isSubmitting}
              className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
              Sign in
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
