import axios from "axios"

export function register(firstname: string, lastname: string, email: string, password: string) {
  return axios.post('/auth/register', {
    firstname,
    lastname,
    email,
    password,
  })
}

export function login(email: string, password: string)  {
  return axios.post('/auth/login', {
    email,
    password,
  })
}

export function logout() {
  return axios.post('/auth/logout', {    })
}
