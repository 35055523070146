// https://chat.openai.com/share/ea3f33e2-03a8-4269-babd-b41a32100ecf

import React, { useState, useEffect, createContext, useContext, ReactElement } from 'react';
import axios from 'axios';
import {register, login, logout} from '../../repositories/auth'
import { getUserMe } from '../../repositories/user';
import { User, adaptObjectToUser } from '../../models/user';

interface AuthProviderProps {
  children: ReactElement;
}

// Define a type for the AuthContext
interface AuthContextType {
  user: User | null;
  isAuthenticated: () =>  Promise<boolean>;
  register: (firstname: string, lastname: string, email: string, password: string) => Promise<any>;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loading: boolean;
}

function isAuthenticated() {
  return getUserMe().then((u) => !!u)
}

// Create a context with a default value
const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated,
  register,
  login,
  logout,
  setUser: () => {},
  loading: true,
});

// Export the hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }:AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setUser(await getUserMe());
      } catch (error) {
        console.error('Authentication check failed:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const authContextValue = {
    user,
    isAuthenticated,
    register,
    login,
    logout,
    setUser,
    loading,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
