import axios from "axios";
import { CampaignDeliveryType, CampaignSequenceType, adaptObjectToCampaign } from "../models/campaign";
import { adaptObjectToLead } from "../models/lead";

export function getLeadsByCampaignId(campaignId: string) {
    return axios.get(`/campaign/${campaignId}/lead`, {    })
        .then((response)=> response?.data?.map(adaptObjectToLead))
}

export function createLeads(campaignId: string, items: object[]) {
    return axios
        .post(`/campaign/${campaignId}/lead`, {  
            items,
        })
        .then((response)=> response?.data?.map(adaptObjectToLead))
}

export function removeAllLeads(campaignId: string) {
    return axios
        .delete(`/campaign/${campaignId}/lead`, {  })
}
