import * as yup from 'yup';
import { Formik, Field, Form, FieldProps, FormikConfig } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
// import GoogleLogo from '../../assets/google-sso-logo.svg';
import countryList from '../../lib/i18n/world.json';
import {
  NotificationType,
  useNotificationCenter
} from '../../lib/notifications/NotificationProvider';
import axios from 'axios';
import { useAuth } from '../../lib/auth/AuthProvider';

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

const INITIAL_VALUES = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  passwordConfirmation: ''
};

const LIVE_VALIDATIONS = [
  { schema: yup.string().min(8), instruction: 'At least 8 characters' },
  { schema: yup.string().matches(/[0-9]/), instruction: 'At least 1 digit character' },
  { schema: yup.string().matches(/[a-z]/), instruction: 'At least 1 lowercase character' },
  { schema: yup.string().matches(/[A-Z]/), instruction: 'At least 1 uppercase character' },
  {
    // schema: yup.string().matches(/^(.*[!@#\$%\^&\*]+)/),
    schema: yup.string().matches(/[\@\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/),
    instruction: 'At least 1 special character'
  }
];

function getCharacterValidationError(str: string) {
  return `Password must contain at least 1 ${str} character`;
}

const loginSchema = yup.object({
  firstname: yup.string().required('Please enter your first name'),
  lastname: yup.string().required('Please enter your last name'),
  email: yup.string().email('Please fill a valid email').required('Please enter your email'),
  password: yup
    .string()
    .required('Please enter your password')
    .min(8, 'Password must have at least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .matches(
      /[\@\!\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/,
      // /^(.*[!@#\$%\^&\*]+)/,
      getCharacterValidationError('special')
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match!")
    .required('Please confirm your password'),
});

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function RegisterForm() {
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const notificationCenter = useNotificationCenter();
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <>
      {/* <div>
        <div>
          <p className="text-sm font-normal leading-6">
            Enter your credentials to access your account
          </p>
          <a
            href="#"
            className="mt-4 flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-1.5 ring-1 ring-inset ring-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0] sm:max-w-[200px]">
            <img src={GoogleLogo} />
            <span className="text-sm font-semibold leading-6">Google</span>
          </a>

          <div className="relative mt-10">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">or</span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="mt-10">
        <Formik<FormValues>
          initialValues={INITIAL_VALUES}
          validationSchema={loginSchema}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            auth.register(values.firstname, values.lastname, values.email, values.password)
              .then(() => {
                  navigate('/login');
                  setSubmitting(false);
                })
              .catch((err) => {
                notificationCenter?.showNotification({
                  type: NotificationType.FAIL,
                  title: 'User already exist',
                  content:
                    'We could not create your account because a user already exist for this email.',
                  autoDismissAfter: 5
                });
                setSubmitting(false);
              });
          }}>
          {({ isValid, isSubmitting, status }) => (
            <Form className="space-y-6">
              {status?.formError && (
                <p className="text-center text-sm text-red-600">{status?.formError}</p>
              )}
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 border-b border-gray-900/10 pb-12 sm:grid-cols-6">
                <h2 className="col-span-full text-base font-semibold leading-7 text-gray-900">
                  Personal Information
                </h2>
                <Field name="firstname">
                  {({ field, meta }: FieldProps) => (
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="firstname"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        First Name
                      </label>
                      <div className="mt-2">
                        <input
                          id="firstname"
                          type="text"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          autoComplete="given-name"
                          required
                          aria-invalid={meta.touched && !!meta.error}
                          className={classNames(
                            meta.touched && meta.error
                              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                              : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                      {meta.touched && !!meta.error && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="lastname">
                  {({ field, meta }: FieldProps) => (
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="lastname"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Last Name
                      </label>
                      <div className="mt-2">
                        <input
                          id="lastname"
                          type="text"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          autoComplete="family-name"
                          required
                          aria-invalid={meta.touched && !!meta.error}
                          className={classNames(
                            meta.touched && meta.error
                              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                              : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                            'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                      {meta.touched && !!meta.error && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div className="flex flex-col gap-x-6 gap-y-8">
                <h2 className="col-span-full text-base font-semibold leading-7 text-gray-900">
                  Credentials Information
                </h2>

                <Field name="email">
                  {({ field, meta }: FieldProps) => (
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          type="email"
                          autoComplete="email"
                          required
                          aria-invalid={meta.touched && !!meta.error}
                          className={classNames(
                            meta.touched && meta.error
                              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                              : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                            'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                      {meta.touched && !!meta.error && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="password">
                  {({ field, meta }: FieldProps) => (
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="password"
                          type="password"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onFocus={() => setIsPasswordFocused(true)}
                          onBlur={(event) => {
                            setIsPasswordFocused(false);
                            field.onBlur(event);
                          }}
                          autoComplete="new-password"
                          required
                          aria-invalid={meta.touched && !!meta.error}
                          className={classNames(
                            meta.touched && meta.error
                              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                              : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                            'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                      {field.value !== '' &&
                        isPasswordFocused &&
                        LIVE_VALIDATIONS.map((validation, index) => {
                          const isInputValid = validation.schema.isValidSync(field.value);
                          return (
                            <p
                              key={index}
                              className={classNames(
                                'mt-2 flex items-center gap-0.5 text-sm',
                                isInputValid ? 'text-green-600' : 'text-red-600'
                              )}>
                              {isInputValid ? (
                                <CheckIcon className="h-4 w-4" color="currentColor" />
                              ) : (
                                <ExclamationCircleIcon className="h-4 w-4" color="currentColor" />
                              )}
                              {validation.instruction}
                            </p>
                          );
                        })}

                      {!isPasswordFocused && meta.touched && !!meta.error && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>

                <Field name="passwordConfirmation">
                  {({ field, meta }: FieldProps) => (
                    <div>
                      <label
                        htmlFor="passwordConfirmation"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Password confirmation
                      </label>
                      <div className="mt-2">
                        <input
                          id="passwordConfirmation"
                          type="password"
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          autoComplete="new-password"
                          required
                          aria-invalid={meta.touched && !!meta.error}
                          className={classNames(
                            meta.touched && meta.error
                              ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                              : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-yellow-600',
                            'block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                      {meta.touched && !!meta.error && (
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="mt-10 flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
                  Register
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
