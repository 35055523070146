import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import NewCampaignForm, { CampaignFormProps } from './NewCampaignForm';

interface CampaignCreationModalProps {
  onCreateCampaign: CampaignFormProps['onCreateCampaign'];
  open: boolean;
  isSubmitting: boolean;
  onClose: () => void;
}
export default function CampaignCreationModal({
  onCreateCampaign,
  open,
  isSubmitting,
  onClose
}: CampaignCreationModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-8">
                <div>
                  <div className=" text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Campaign creation
                    </Dialog.Title>

                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Let's start creating your campaign by giving it a name. <br />
                        Or leave it empty, and we'll generate one for you.
                      </p>
                    </div>

                    <div className="mt-2">
                      <NewCampaignForm
                        onCreateCampaign={onCreateCampaign}
                        isSubmitting={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
