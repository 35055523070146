import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider } from './lib/auth/AuthProvider';
import { NotificationProvider } from './lib/notifications/NotificationProvider';
import { NotificationCenter } from './lib/notifications/NotificationProvider';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

const queryClient = new QueryClient()

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AuthProvider>
      <NotificationProvider>
        <NotificationCenter />
        <QueryClientProvider client={queryClient}>
        <Outlet />
        </QueryClientProvider>
      </NotificationProvider>
    </AuthProvider>
  );
}
