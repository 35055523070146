import SniprLogo from '../../assets/logo_color.png';
// import GoogleLogo from '../../assets/google-sso-logo.svg';
import LoginForm from './LoginForm';
import { NavLink, Navigate, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { useAuth } from '../../lib/auth/AuthProvider';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-10 w-auto" src={SniprLogo} alt="Snipr" />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <LoginForm
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              try {
                await auth.login(values.email, values.password);

                // If we have been redirected to login page from another page
                // a.k.a user session ended
                if (location.state?.from) {
                  navigate(location.state.from, { replace: true });
                } else navigate('/campaign');
              } catch (err: any) {
                setStatus({ formError: 'Invalid credentials' });
              } finally {
                setSubmitting(false);
              }
            }}
          />

          {/* <div>
            <div className="relative mt-10">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">Or continue with</span>
              </div>
            </div>

            <div className="mt-6">
              <a
                href="#"
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-1.5 ring-1 ring-inset ring-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]">
                <img src={GoogleLogo} />
                <span className="text-sm font-semibold leading-6">Google</span>
              </a>
            </div>
          </div> */}
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{' '}
          <NavLink
            to="/register"
            className="font-semibold leading-6 text-yellow-500 hover:text-yellow-400">
            Create your account
          </NavLink>
        </p>
      </div>
    </div>
  );
}
