import { CheckIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as yup from 'yup';
import { Campaign } from '../../../../../models/campaign';
import LeadsTable, { Column } from './LeadsTable';
import { NotificationType, useNotificationCenter } from '../../../../../lib/notifications/NotificationProvider';
import { InvalidFileContentError, InvalidFileFormatError, InvalidFileTypeError, TooManyRowsError } from '../../../../../lib/csvParser/errors';
import CSVParser from '../../../../../lib/csvParser/csvParser';
import { useMutation, useQuery } from 'react-query';
import { createLeads, getLeadsByCampaignId, removeAllLeads } from '../../../../../repositories/lead';
import { Lead } from '../../../../../models/lead';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';

export interface LeadImportCurrentStepProps {
  campaign: Campaign;
  onContinue: () => void;
  onUpdate: () => void;
}

const COLUMNS: Column[] = [
  {
    name: 'Firstname',
    slug: 'firstname',
    validator: yup.string(),
  },
  {
    name: 'Lastname',
    slug: 'lastname',
    validator: yup.string(),
  },
  {
    name: 'Email',
    slug: 'email',
    validator: yup.string().email('Lead email has incorrect format'),
  },
  {
    name: 'Company name',
    slug: 'company_name',
    validator: yup.string(),
  },
  {
    name: 'Website URL',
    slug: 'website_url',
    validator: yup.string().url(),
  },
  {
    name: 'Youtube URL',
    slug: 'youtube_url',
    validator: yup.string().url(),
  },
  {
    name: 'Linkedin',
    slug: 'linkedin_url',
    validator: yup.string().url(),
  }
];

export function LeadImportCurrentStep({ campaign, onContinue, onUpdate }: LeadImportCurrentStepProps) {
  const notificationCenter = useNotificationCenter();

  const [columns, setColumns] = useState(COLUMNS);

  const { isLoading : loading, refetch: refetchLeads, data: leads } = useQuery<Lead[]>(
    [campaign, "get-all-leads"],
    async () => {
      const leads: Lead[] = await getLeadsByCampaignId(campaign.id);
      const columnSlugs = _.uniq(_.flatten(leads.map((lead: Lead) => Object.keys(lead.properties ?? {}))))
      const columnSlugsWithBaseExcluded = _.without(columnSlugs, ...COLUMNS.map(c => c.slug))
      setColumns(
        COLUMNS.concat(
          ...columnSlugsWithBaseExcluded.map(field => ({
            name: field,
            slug:field, 
            validator: yup.string(),
          })),
        )
      )
      return leads;
    },
  );

  const { mutate: updateLeads } = useMutation(async (variables: {leads: object[]}) => {
    await removeAllLeads(campaign.id);
    await createLeads(campaign.id, variables.leads)
    onUpdate()
    return refetchLeads();
  });

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    let totalParsedData: object[] = [];
    let isError = false;
    for (const file of acceptedFiles) {
      try {
        const leadsCsvSchema = yup.object(COLUMNS.reduce((acc: any, curr) => {
          acc[curr.slug] = curr.validator;
          return acc;
        }, {}))
        const parser = new CSVParser<object>(file, { maxRows: 500, schema: leadsCsvSchema });
        const parsedData = await parser.parse();
        totalParsedData.push(...parsedData);
      } catch (err: unknown) {
        let title = null;
        let content = null;
        if (err instanceof InvalidFileTypeError) {
          title = 'Invalid file type';
          content = `The file ${file.name} does not have the right format. Only CSV files are allowed.`;
        } else if (err instanceof InvalidFileContentError) {
          title = 'Invalid file content';
          content = `The file ${file.name} is not a valid CSV file. The following error happened with row n°${err.details?.row} : ${err.details?.detailedMessage}`;
        } else if (err instanceof InvalidFileFormatError) {
          title = 'Invalid file format';
          content = `The file ${file.name} is not a valid CSV file. Please verify the file format.`;
        } else if (err instanceof TooManyRowsError) {
          title = 'Too many rows';
          content = `The file ${file.name} contains ${err.details?.maxEntries} leads. Your file can contain up to 500 leads maximum.`;
        }

        if (title !== null && content !== null) {
          notificationCenter.showNotification({
            title,
            content,
            type: NotificationType.FAIL,
            autoDismissAfter: 10
          });
        }
        isError = true;
      }
    }

    if (acceptedFiles.length > 1 && totalParsedData.length > 500) {
      notificationCenter.showNotification({
        title: 'Too many rows',
        content: `The files you submitted contain ${totalParsedData.length} leads altogether. Please limit the leads number to 500 leads max.`,
        type: NotificationType.FAIL,
        autoDismissAfter: 10
      });
      isError = true;
    }

    if (!isError) {
      const notificationId = notificationCenter.showNotification({
        title: 'Success',
        content: `Please wait a second, we are processing your ${
          acceptedFiles.length > 1 ? 'files' : 'file'
        }`,
        type: NotificationType.SUCCESS
      });

      updateLeads({
        leads: totalParsedData,
      });
    }
  }, []);

  function onClearLeads() {
    updateLeads({ leads: [] });
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {leads && leads.length === 0 && (
        <>
          <p className="flex justify-center mb-4">
            Check out the&nbsp;
            <NavLink target="_blank" className="text-yellow-500" to="https://docs.google.com/spreadsheets/d/13-Mb-ILDoaL0HOZB7XMVm-gxF_AdLquoiaHuvrWuEWA/edit#gid=996469607">
              template!
            </NavLink>
          </p>
          <div
            className="flex justify-center rounded-lg border border-dashed border-gray-900/25 bg-gray-50 px-6 py-10"
            {...getRootProps()}>
            <div className="text-center">
              <TableCellsIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
              <div className="mt-4 flex justify-center text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-semibold text-yellow-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-yellow-500 focus-within:ring-offset-2 hover:text-yellow-400">
                  <span>Upload a file</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    {...getInputProps()}
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                Only CSV files are allowed. Maximum 500 leads.
              </p>
            </div>
          </div>
        </>
      )}

      {leads && leads.length > 0 && (
        <div className="sm:px-2">
          <div className="flex justify-center mb-4">
            <LeadImportCompleteStep leads={leads} />
          </div>
            
          <LeadsTable leads={leads ?? []} columns={columns} />
          <div className="mt-4 flex justify-between">
            <button
              type="button"
              onClick={onClearLeads}
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
              <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Clear
            </button>
            <button
              type="button"
              onClick={onContinue}
              className="rounded-md bg-yellow-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500">
              Continue
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export interface LeadImportCompleteStepProps {
  leads: Lead[]
}
export function LeadImportCompleteStep({ leads }: LeadImportCompleteStepProps) {
  return (
    <div className="group relative flex items-start">
      <span className="flex h-9 items-center">
        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500">
          <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
      </span>
      <div className="ml-4 flex min-w-0 flex-1 flex-col">
        <span className="text-sm font-medium">Import</span>
        <span className="text-sm text-gray-500">
        <span className="mt-1 inline-flex items-center text-sm text-green-500">
            <span>
              {leads.length} {leads.length > 1 ? 'leads' : 'lead'} imported
            </span>
          </span>
        </span>
      </div>
    </div>
  );
}
