import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useEffect, useState } from 'react';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const auth = useAuth();

  const [isAuth, setIsAuth] = useState<boolean|null>(null);

  useEffect(() => {
    auth.isAuthenticated().then(setIsAuth).catch(() => setIsAuth(false))
  }, [])

  if (isAuth === false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
  // return <>
  //   {!loading && !isError && isAuth && children}
  //   {!loading && (isError || !isAuth) &&
  //     <Navigate to="/login" state={{ from: location }} replace />
  //   };
  // </>;
}
